import React from "react";
import PropTypes from "prop-types";

import VideoEmbed from "../components/VideoEmbed";

import "../styles/pages/homepage.scss";

const HomePageTemplate = ({ title, subtitle, video }) => {
  return (
    <div className="homepage">
      <div className="homepage__titles">
        <h1>{title}</h1>
        <span className="homepage__subtitle h h2">{subtitle}</span>
      </div>
      <VideoEmbed className="homepage__video" url={video} autoplay fullscreen />
    </div>
  );
};

HomePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  video: PropTypes.string,
};

export default HomePageTemplate;
